import React, { useEffect, useState } from "react";
import poppy from "../images/home/california-poppy.jpg";
import poppyBw from "../images/home/california-poppy-bw.png";
import sunset from "../images/home/sunset-glow.jpeg";
import sunsetBw from "../images/home/sunset-glow-bw.png";
import map from "../images/home/map.jpg";
import mapBw from "../images/home/map-bw.jpg";
import maple from "../images/home/maple.svg";
// import { Link, NavLink, useNavigate } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import classNames from "classnames";
import useWindowDimensions from "../utils/useWindowDimensions";
// import { Timeline } from "gsap/gsap-core";

export default function Home(props) {
  //   const triggerRef = useRef(null);
  const [mapleLeaves, setMapleLeaves] = useState(null);
  const thewindow = useWindowDimensions();
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    // const el = triggerRef.current;
    // let x =
    //   thewindow.windowType === "tablet" || thewindow.windowType === "phone"
    //     ? "5%"
    //     : "20%";

    if (thewindow.windowType !== "tablet" && thewindow.windowType !== "phone") {
      //   gsap.to(el.querySelectorAll("p"), {
      //     x: x,
      //     opacity: 1,
      //     duration: 2,
      //     //   delay: 3,
      //     stagger: 0.75,
      //     ease: "power4.inOut",
      //     scrollTrigger: {
      //       trigger: el,
      //     },
      //   });
      var maples = [];
      for (var i = 0; i < 80; i++) {
        var rotation = Math.floor(Math.random() * 75);
        rotation *= Math.round(Math.random()) ? 1 : -1;
        let leaf = {
          x: `${Math.random() * 200}vw`,
          y: `${Math.random() * 250}vh`,
          w: `${Math.random() * 10 + 8}rem`,
          r: `${Math.random() * rotation}`,
        };
        maples.push(leaf);
      }
      setMapleLeaves(maples);
    }
  }, []);

  function getMapleLeaves() {
    var leafs = [];
    for (var i = 0; i < 80; i++) {
      let leaf = (
        <img
          src={maple}
          key={"leaf" + i}
          style={{
            position: "absolute",
            left: mapleLeaves[i].x,
            top: mapleLeaves[i].y,
            width: mapleLeaves[i].w,
            transform: `rotate(${mapleLeaves[i].r}deg)`,
            opacity: 0.2,
          }}
        />
      );
      leafs.push(leaf);
    }
    return leafs;
  }
  return (
    <React.Fragment>
      <div
        className={classNames(
          "page-wrapper page home",
          props.current === "home" ? "page-main" : "page-left"
        )}
      >
        <div className="text-wrapper">
          <div className="title home__title">
            <span className="title-large">Artists</span>
            <span className="title-small">in the</span>
            <span className="title-large">Country</span>
          </div>
          <div className="year">2022</div>
          {mapleLeaves && getMapleLeaves()}
          {/* <img src={maple} /> */}
        </div>

        <div className="about-text">
          <p className="para">
            Artists in the Country is an original art show and sale created,
            developed and sponsored by Corby Custom Framing.
          </p>
          <p className="para">
            Over the years Corby Custom Framing has framed for many local
            artists. We’ve seen the quality of artwork that our local artists
            produce and want to give them an opportunity to show their art to
            their neighbours, friends and area residents, in their own community
            instead of traveling to larger centres such as Hamilton or
            Burlington.
          </p>
          <p className="para">
            We hope the annual show will raise our community’s awareness of the
            high caliber of local talent in the Flamborough area by showing a
            wide variety of original artwork. At the same time we introduce more
            people to the easy rural atmosphere and friendly services offered by
            Corby Custom Framing.
          </p>
          <p>
            Artists In The Country 2022 is Saturday, June 4, from 9am to 5pm and
            Sunday, June 5, from 11am to 5pm.
          </p>
        </div>
        <div className="location-text">
          <p>Corby’s Custom Framing is located in Freelton, Ontario.</p>{" "}
          <p>Click the map to get further contact details.</p>
        </div>
        <div className="home-images">
          <ul>
            <li>
              <div className="images">
                <picture>
                  <img src={poppy} alt="" />
                </picture>
                <picture>
                  <img src={poppyBw} alt="" className="default-image" />
                </picture>
              </div>
            </li>
            <li>
              <a
                onClick={() => {
                  props.nav("artists");
                }}
              >
                <div className="images">
                  <span>Artists</span>
                  <picture>
                    <img src={sunset} alt="" />
                  </picture>
                  <picture>
                    <img src={sunsetBw} alt="" className="default-image" />
                  </picture>
                </div>
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  props.nav("contact");
                }}
              >
                <div className="images">
                  <span>Contact</span>
                  <picture>
                    <img src={map} alt="" />
                  </picture>
                  <picture>
                    <img src={mapBw} alt="" className="default-image" />
                  </picture>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
}
