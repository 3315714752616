import React, { useState, useEffect, useRef } from "react";
import ArtistCard from "../components/ArtistCard";
import { gsap } from "gsap";
import classNames from "classnames";
export default function Artists(props) {
  //   let nav = useNavigate();
  const [artistData, setArtistData] = useState();

  function handleArtistSelected() {
    // console.log(window.scrollY);
    // window.scrollTo(0, 0);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  useEffect(() => {
    fetch("./data/artist-data.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then((response) => {
      response.json().then((response) => {
        // console.log(response);
        setArtistData(response);
      });
    });
  }, []);

  let screen = useRef(null);
  //   let body = useRef(null);
  useEffect(() => {
    const el = screen.current;
    // const bel = body.current;
    gsap.fromTo(
      el,
      { left: "0%" },
      {
        duration: 1,
        ease: "power4.inOut",
        left: "-100%",
        //   delay: 2,
      }
    );
    // gsap.to(el, {
    //   duration: 1,
    //   left: "0%",
    //   delay: 0.3,
    // });
    // gsap.to(el, { top: "-100%", delay: 1.4 });
    // gsap.fromTo(bel, { opacity: 0 }, { opacity: 1, delay: 0.3 });
    // return () => {
    //   gsap.to(bel, { opacity: 0, duration: 1 });
    // };
  }, []);
  //   function test(message, destination) {
  //     const el = screen.current;
  //     gsap.to(".home", { opacity: 0, duration: 0.5, ease: "power4.inOut" });
  //     gsap.fromTo(
  //       el,
  //       { left: "-100%" },
  //       { left: 0, ease: "power4.inOut", duration: 0.4, delay: 0.1 }
  //     );
  //     setTimeout(() => {
  //       //   console.log(destination);
  //       //   nav(destination);
  //     }, 500);
  //   }
  return (
    <React.Fragment>
      <div
        className={classNames(
          "page-wrapper page",
          props.current === "artists" ? "page-main" : "page-right"
        )}
      >
        <div className="artists__title">Artists at the 2022 show</div>
        <div className="artists__container">
          {artistData &&
            artistData.map((artist) => {
              return (
                <ArtistCard
                  key={artist.name}
                  artist={artist}
                  scrollToTop={handleArtistSelected}
                />
              );
            })}
        </div>
      </div>
    </React.Fragment>
  );
}
