import React, { useState } from "react";
import Home from "./pages/Home";
import Entertainment from "./pages/Entertainment";
import Artists from "./pages/Artists";
import Contact from "./pages/Contact";
import classNames from "classnames";
import "./styles/styles.css";
function App() {
  //   let nav = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [current, setCurrent] = useState("home");
  function changePage(destination) {
    window.scrollTo(0, 0);
    setMenuOpen(false);
    setCurrent(destination);
    // setTimeout(() => {}, 1000);
  }

  // function open(){}
  return (
    <div className="App">
      <header className="header">
        <div
          className={classNames(
            "main-menu",
            menuOpen ? "menu-open" : "menu-closed"
          )}
        >
          <button
            className="nav-button"
            onClick={() => {
              setMenuOpen(!menuOpen);
            }}
          >
            <span className={classNames(menuOpen ? "open" : "closed")}>
              <span className="line"></span>
            </span>
            <span className={classNames(menuOpen ? "open" : "closed")}>
              <span className="line"></span>
            </span>
          </button>
          <button
            className="loc-button"
            onClick={() => {
              changePage("home");
            }}
          >
            Home
          </button>
          <button
            className="loc-button"
            to="/artists"
            onClick={() => {
              changePage("artists");
            }}
          >
            Artists
          </button>
          <button
            className="loc-button"
            to="/entertainment"
            onClick={() => {
              changePage("entertainment");
            }}
          >
            Entertainment
          </button>
          <button
            className="loc-button"
            to="/contact"
            onClick={() => {
              changePage("contact");
            }}
          >
            Contact
          </button>
        </div>
        <div className="mobile-menu">
          <button
            className="loc-m-button"
            style={current === "home" ? { fontWeight: "bold" } : {}}
            onClick={() => {
              changePage("home");
            }}
          >
            Home
          </button>
          <button
            className="loc-m-button"
            style={current === "artists" ? { fontWeight: "bold" } : {}}
            onClick={() => {
              changePage("artists");
            }}
          >
            Artists
          </button>
          <button
            className="loc-m-button"
            style={current === "entertainment" ? { fontWeight: "bold" } : {}}
            onClick={() => {
              changePage("entertainment");
            }}
          >
            Entertainment
          </button>
          <button
            className="loc-m-button"
            style={current === "contact" ? { fontWeight: "bold" } : {}}
            onClick={() => {
              changePage("contact");
            }}
          >
            Contact
          </button>
        </div>
      </header>

      <Home current={current} nav={changePage} />
      <Artists current={current} />
      <Entertainment current={current} />
      <Contact current={current} />
    </div>
  );
}

export default App;
