import React, { useState, useEffect } from "react";
import classNames from "classnames";
import ContactCard from "../components/ContactCard";
export default function Contact(props) {
  // const contactCardDetails=
  const [contactData, setContactData] = useState();

  useEffect(() => {
    fetch("./data/contact-data.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then((response) => {
      response.json().then((response) => {
        setContactData(response);
      });
    });
  }, []);

  return (
    <React.Fragment>
      <div
        className={classNames(
          "page-wrapper page",
          props.current === "contact" ? "page-main" : "page-right"
        )}
      >
        <div className="contact__wrapper">
          {/* <div className="contact__heading">Contact Us</div> */}
          <div className="contact__instructions">
            For More Information about the Artists in the Country Original Art
            show and sale, or Corby Custom Framing please contact us in one of
            the following ways:
          </div>
          <div className="contact__cards">
            {contactData &&
              contactData.map((contact, index) => {
                return (
                  <ContactCard contact={contact} key={"contact" + index} />
                );
              })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
