import React, { useState, useEffect } from "react";
import classNames from "classnames";
import Modal from "../components/Modal";

export default function ArtistCard(props) {
  const artist = { ...props.artist };
  const [artistSelected, setArtistSelected] = useState("");
  const handleClose = () => {
    setArtistSelected("");
  };
  useEffect(() => {
    props.scrollToTop();
  }, [artistSelected, props]);
  return (
    <div
      className={classNames(
        "artist__card",
        "artist__card-" + artist.defaultImageType
      )}
    >
      <div onClick={() => setArtistSelected(artist.name)}>
        <img
          src={artist.colorImage}
          alt={artist.defaultImageText}
          className="defaultImage"
        />
        <img
          src={artist.defaultImage}
          alt={artist.defaultImageText}
          className="defaultImage"
        />
        <div className="artist__artist-name">{artist.name}</div>
      </div>
      {artistSelected === artist.name && (
        <Modal
          artist={artist}
          showModal={artistSelected === artist.name ? true : false}
          closeModal={handleClose}
        ></Modal>
      )}
    </div>
  );
}
