import React, { useEffect, useState, createRef, useCallback } from "react";

// import useWindowDimensions from "../utils/useWindowDimensions";

export default function Modal(props) {
  //   const [modalImages, setModalImages] = useState([]);
  //   const [showModal, setShowModal] = useState(false);
  //   const [imageIndex, setImageIndex] = useState(0);
  //   const [modalClosing, setModalClosing] = useState(false);
  //   const { height, width, windowType } = useWindowDimensions();
  const [currentSelected, setCurrentSelected] = useState();
  const [theState, updateTheState] = useState();
  //   const [distanceToRight, setDistanceToRight] = useState();
  const artist = { ...props.artist };
  //   let distanceToRight;

  const drawerRef = createRef();

  const closeModal = () => {
    setTimeout(() => {
      props.closeModal();
    }, 100);
  };
  function getStyle(index) {
    let placesDiff = currentSelected - index;
    return {
      transform: `translate3d(${-14 * placesDiff + 15}vw, 0, ${
        -100 * Math.abs(placesDiff)
      }px)`,
    };
  }
  function getTextStyle(index) {
    if (currentSelected === index) return { opacity: 1 };
    return { opacity: 0 };
    //   return {`opacity:${currentSelected===index?1:0}`}
  }
  function getImageStyle(index) {
    let placesDiff = currentSelected - index;

    return {
      filter: `brightness(${Math.max(100 - 50 * Math.abs(placesDiff), 10)}%)`,
    };
  }

  function setSelected(index) {
    setCurrentSelected(index);
    setTimeout(() => {
      updateTheState("index:" + index);
    }, 300);
  }
  useEffect(() => {
    if (drawerRef && props.showModal) {
      drawerRef.current.focus();
    }
  }, [drawerRef, props.showModal]);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === "Escape") {
        closeModal();
      }
    },
    [closeModal]
  );
  useEffect(() => {
    setCurrentSelected(Math.round(artist.images.length / 2) - 1);
    // setDistanceToRight(
    //   artist.images.length - Math.round(artist.images.length / 2)
    // );
  }, [props.showModal]);
  return (
    <div
      className={props.showModal ? "modal-show" : "modal-hide"}
      ref={drawerRef}
      onKeyDown={handleKeyDown}
      tabIndex={-1}
      onClick={() => {
        closeModal();
      }}
    >
      <dialog
        open={`"${props.showModal ? true : false}"`}
        className="modal-container"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <div
          className="modal-close-button"
          onClick={() => {
            closeModal();
          }}
        >
          &#x2715;
        </div>
        <div className="artist__info">
          <div className="artist__name">{artist.name}</div>
          <div
            className="artist__bio"
            dangerouslySetInnerHTML={{ __html: artist.bio }}
          ></div>
        </div>
        <div className="modal-image-viewpane">
          <div className="artist__gallery">
            {artist.images && artist.images.length > 0
              ? artist.images.map((image, index) => {
                  return (
                    <label
                      className="image-container"
                      style={getStyle(index)}
                      id={"image" + index}
                      key={index}
                    >
                      <>
                        <img
                          src={image.imageURL}
                          alt={image.imageText}
                          className="displayImage"
                          style={getImageStyle(index)}
                          onClick={() => {
                            setSelected(index);
                          }}
                        />
                        <span
                          className="image-name"
                          style={getTextStyle(index)}
                        >
                          {image.imageName}
                        </span>
                      </>
                    </label>
                  );
                })
              : ""}
            <div className="button-group">
              {artist.images.map((image, index) => {
                return (
                  <input
                    type="radio"
                    key={`radio${index}`}
                    name="imageSelected"
                    value={index || 0}
                    id={"s" + index}
                    checked={currentSelected === index}
                    onChange={() => {
                      setSelected(index);
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </dialog>
    </div>
  );
}
