import React from "react";

export default function ContactCard(props) {
  const contact = { ...props.contact };
  return (
    <div className="contact__card">
      <div className="contact__icon">
        <img src={contact.icon} alt={contact.contactType} />
      </div>
      <div
        className="contact__info"
        dangerouslySetInnerHTML={{ __html: contact.text }}
      ></div>
    </div>
  );
}
