import React from "react";
import classNames from "classnames";
// import { gsap } from "gsap";
import aaof from "../images/entertainment/aaof.png";

export default function Entertainment(props) {
  return (
    <React.Fragment>
      <div
        className={classNames(
          "page-wrapper page home",
          props.current === "entertainment" ? "page-main" : "page-right"
        )}
      >
        <div className="Entertainment__container">
          <div className="Entertainment__text">
            <p className="Entertainment__heading">
              We are pleased to announce the 17th Annual Raffle of Original Art
              and Art Prints
            </p>
            <div className="Entertainment__details-container">
              <a href="https://anadoptflam.com/" target="_blank">
                <img src={aaof} alt="" />
              </a>
              <p className="Entertainment__details">
                All proceeds go to the Animal Adoptions of Flamborough who give
                medical aid and find homes for the lost, stray and abandoned
                animals of Flamborough. Many of our participating Artists donate
                Artwork to be raffled.
              </p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
